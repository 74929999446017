import React from 'react'
import ArticlesSection from '../../components/sections/ArticlesSection'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { FaArrowLeft } from 'react-icons/fa'
import { graphql, Link } from 'gatsby'
import Layout from '../../components/Layout'
import WeDoCtoBanner from '../../components/sections/WeDoCtoBanner'

const ArticleTemplate = ({ data: { contentfulArticle: article } }) => {
  const {
    title,
    publishDate,
    tags,
    readTime,
    heroImage,
    content: { raw: rawContent },
  } = article
  const pageData = { name: title, title: title, hero: heroImage }
  const articlesData = tags

  return (
    <Layout page={pageData} showBelow={300}>
      <article className='container mt-8 px-2 md:px-4'>
        <div className='flex flex-col md:flex-row justify-between'>
          <Link
            className=' flex flex-row justify-left items-center'
            to={'/articles'}
          >
            <FaArrowLeft className='text-xl text-gray-500' />
            <p className='pb-0 pl-4 m-0' to='/articles'>
              Go To All Articles
            </p>
          </Link>
          <div className='flex items-center justify-around mt-4 md:mt-0 md:justify-right'>
            {tags &&
              tags.map(({ name: tagName }, index) => {
                return (
                  <span
                    className={`w-max ${
                      index > 0 && 'ml-2'
                    } p-2 bg-primary text-sm font-spar text-secondary-dark shadow-sm rounded-lg px-4 lowercase`}
                  >
                    {tagName}
                  </span>
                )
              })}
          </div>
        </div>
        <div className='flex flex-col md:flex-row text-center md:text-left justify-between mt-4'>
          <span className='text-base text-gray-500 pb-0 md:text-right lowercase'>
            read time: {readTime} mins
          </span>
          <span className='text-base text-gray-500 pb-0 lowercase'>
            published {publishDate}
          </span>
        </div>
        <div className='pt-0 mt-0'>
          <div className='text-center md:text-justify'>
            {documentToReactComponents(JSON.parse(rawContent))}
          </div>
        </div>
      </article>
      <ArticlesSection title='Related' articlesData={articlesData} />
      {/*<WeDoCtoBanner />*/}
    </Layout>
  )
}

export const query = graphql`
  query singleArticleQuery($id: String) {
    contentfulArticle(id: { eq: $id }) {
      content {
        raw
      }
      heroImage {
        gatsbyImageData
      }
      publishDate(formatString: "DD MMMM, YYYY")
      readTime
      tags {
        name
        article {
          title
          tileImage {
            gatsbyImageData
          }
          tags {
            name
          }
          publishDate(formatString: "DD MMMM, YYYY")
        }
      }
      title
    }
  }
`

export default ArticleTemplate
